.container {
    width: 100%;
    height: auto;
    margin-top: 70px;
    padding: 0 50px;
}

.bigTitle {
    width: 100%;
    padding: 40px 0px;
    text-align: center;
    font-size: 27px;
    font-weight: 600;
}

.content {
    width: 100%;
    height: auto;
}

.contentItem {
    width: 100%;
    height: 868px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.contentItemSelectSize {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.leftContent {
    width: 844px;
    height: 100%;
    background-color: #efefef;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
}

.leftContent img {
    width: 80% !important;
    margin: 0 auto;
}

.rightContent {
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}

.sizeContent {
    width: calc(100% - 844px);
    height: auto;
    padding-left: 40px;
}

.contentRightItem {
    width: 100%;
    height: auto;
}

.title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

.optionItem {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 9px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: 30px;
    flex-direction: column;
    gap: 15px;
    max-height: 700px;
    overflow-y: auto;
    scrollbar-width: none;
    /* scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;  */
}

.colorss {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.colorItems {
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 3px;
    overflow: hidden;
    min-width: 80px;
    min-height: 80px;
}
.colorss img {
    width: 100%;
    height: 100%;
}

.colorss .description {
    font-size: 12px;
    color: gray;
}

.sizeOption {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sizeInfo {
    width: 100%;
    height: 85px;
    background-color: #f6f6f6;
    cursor: pointer;
    border-radius: 4px;
    padding: 18px 24px;
    box-sizing: border-box;
}

.sizeInfo span {
    color: gray;
    font-size: 12px;
}

.havedSize {
    margin-top: 20px;
    text-align: center;
}

.loginText {
    text-align: center;
}

.havedSize span, .loginText span {
    font-weight: 600;
}

.infoDelivery {
    display: flex;
    padding-bottom: 0px;
    width: 100%;
    box-sizing: border-box;
}

.totalPrice {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoPrice {
    width: 100%;
}

.deliveryText {
    font-size: 12px;
}

.infoDelivery {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.buttonSubmit {
    padding: 12px 24px 14px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    background-color: #2d2d2c;
    color: white;
}

.finishButton {
    background-color: transparent;
    border: 1px solid #efefef;
    color: black;
}

.addSize {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 1600px) {
    /* .container {
        width: 100%;
        height: auto;
        padding: 0 200px;
    } */
    
    .contentItem {
        gap: 70px;
    }
}

.contentItemSelectSize .leftContent {
    height: auto;
}

.seeAllDetail {
    font-size: 14px;
    line-height: 20px;
    border-radius: 50px;
    border: 1px solid #efefef;
    display: flex;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    height: 36px;
    margin: 0 auto;
    margin-top: 20px;
}

.headerContent {
    padding: 29px 0 32px;
    font-size: 20px;
    line-height: 28px;
}

@media screen and (max-width: 1200px) {
    .contentItem {
       height: 700px;
    }

    .optionItem {
        max-height: 600px;
    }

    .contentItemSelectSize .leftContent {
        display: none;
    }

    .contentItemSelectSize .sizeContent {
        width: 400px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1000px) {
    .contentItem {
       height: 600px;
    }

    .optionItem {
        max-height: 500px;
    }
}

@media screen and (max-width: 800px) {
    .contentItem {
       height: 400px;
    }

    .optionItem {
        max-height: 300px;
    }
}

@media screen and (max-width: 600px) {
    .contentItem {
       height: 300px;
    }

    .optionItem {
        max-height: 200px;
    }
}