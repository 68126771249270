// 
// general-rtl.scss
//

html {
    direction: rtl;
}

body {
    text-align: right;
}