/*
Template Name: Sprezzy - Admin & Dashboard Template
Author: Themesbrand
Version: 4.1.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";

body {
    direction: ltr;
}

.card {
    text-align: left;
}

.account-pages {
    direction: ltr;
    text-align: left;
}

.account-pages input::placeholder {
    text-align: left;
}

.account-pages .form-check .form-check-input {
    float: left;
    margin-left: 0;
}

.form-check {
    text-align: left !important;
}

.account-pages label.form-check-label {
    margin-left: 1.5 em;
}

.email-sent-custom {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.dropdown-menu-end.dropdown-menu.show {
    text-align: left;
}

.dropdown-menu-end.dropdown-menu.show a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

a.notification-item div {
    gap: 10px;
}

footer {
    left: 0 !important;
    text-align: right;
}

.vertical-menu {
    left: 0;
}

.custom-suit-fabric-collar {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  
}

img.custom-suit-fabric-collar.lapel-Width {
    z-index: 2;
}
  
img.custom-suit-fabric-collar.Lining-color {
    top: 2px;
}

#blog-detail-wiki figure > img {
    width: 100%;
    height: auto; 
    object-fit: contain;
}